import _utils from "./hash/utils";
import _common from "./hash/common";
import _sha from "./hash/sha";
import _ripemd from "./hash/ripemd";
import _hmac from "./hash/hmac";
var exports = {};
var hash = exports;
hash.utils = _utils;
hash.common = _common;
hash.sha = _sha;
hash.ripemd = _ripemd;
hash.hmac = _hmac; // Proxy hash functions to the main object

hash.sha1 = hash.sha.sha1;
hash.sha256 = hash.sha.sha256;
hash.sha224 = hash.sha.sha224;
hash.sha384 = hash.sha.sha384;
hash.sha512 = hash.sha.sha512;
hash.ripemd160 = hash.ripemd.ripemd160;
export default exports;